<div class="h-[48px] toolbar items-center px-8 justify-center bg-white">
  <div id="right" class="flex items-center gap-16 mr-auto flex-1">
    <div class="flex items-center cursor-pointer gap-8">
      <div class="ml-16">
        <twaice-fe-svg-icon
          name="icon_twaice_logo"
          fill="#1362fc"
          color="#1362fc"
          width="100px"
          height="30px"
          (click)="navigateToBaseUrl()"
          [attr.data-intercom-target]="intercomTarget + '-twaice-logo'"
        />
      </div>
    </div>
  </div>

  <ul nz-menu nzMode="horizontal" class="border-none flex flex-1 justify-center">
    <li nz-menu-item [nzSelected]="baseUrlActive()">
      <a
        class="flex items-center gap-8"
        (click)="navigateToBaseUrl()"
        [attr.data-intercom-target]="intercomTarget + '-' + title + '-tab'"
      >
        {{ title }}
      </a>
    </li>

    <li *ngIf="!isDemoEnabled" nz-menu-item nzMatchRouter [attr.data-intercom-target]="intercomTarget + '-simulation-tab'">
      <a class="flex items-center gap-8" [routerLink]="'/model-library'" [queryParamsHandling]="'preserve'">
        Simulation Solutions
      </a>
    </li>

    <li *ngIf="isDemoEnabled" nz-menu-item nzMatchRouter [attr.data-intercom-target]="intercomTarget + '-simulation-tab'">
      <a class="flex items-center gap-8" [routerLink]="'/model-demo'" [queryParamsHandling]="'preserve'">
        Simulation Solutions
      </a>
    </li>

    <li nz-menu-item nzMatchRouter [attr.data-intercom-target]="intercomTarget + '-on-demand-tab'">
      <a class="flex items-center gap-8" [routerLink]="'/professional-services'" [queryParamsHandling]="'preserve'">
        Professional Services
      </a>
    </li>
  </ul>

  <div id="left" class="flex items-center ml-auto flex-1 justify-end">
    <button
      nz-button
      nzType="text"
      (click)="openHelpCenter()"
      class="flex justify-center items-center gap-8"
      [attr.data-intercom-target]="intercomTarget + '-help-button'"
    >
      <twaice-fe-svg-icon name="book" source="tabler" color="#040F26" size="16px" />
      <span class="hidden lg:block">Knowledge Center</span>
    </button>

    <button
      nz-button
      nzType="text"
      nz-dropdown
      [nzDropdownMenu]="userMenu"
      nzTrigger="click"
      class="flex justify-center items-center gap-8"
      [attr.data-intercom-target]="intercomTarget + '-settings-button'"
    >
      <twaice-fe-svg-icon name="user-circle" source="tabler" color="#040F26" size="16px" />
      <span class="hidden lg:block">Account Settings</span>
    </button>
  </div>
</div>

<nz-dropdown-menu #userMenu="nzDropdownMenu" xPosition="before" [attr.data-intercom-target]="intercomTarget + '-settings-menu'">
  <ul nz-menu nzSelectable>
    <div class="flex flex-col justify-start px-16 py-16 mb-8">
      <div class="flex flex-col justify-start break-words">
        <h4 class="text-primary-text m-0">{{ (loggedInUser$ | async)?.username }}</h4>
      </div>
    </div>

    <li
      nz-menu-item
      routerLink="/privacy-policy"
      queryParamsHandling="preserve"
      data-test="settings-link"
      [attr.data-intercom-target]="intercomTarget + '-settings-policy'"
    >
      <div class="flex items-center gap-8">
        <twaice-fe-svg-icon name="lock" source="tabler" color="#040F26" size="20px" />
        <span>Privacy Policy</span>
      </div>
    </li>

    <li
      nz-menu-item
      routerLink="/settings"
      queryParamsHandling="preserve"
      data-test="settings-link"
      [attr.data-intercom-target]="intercomTarget + '-settings-settings'"
    >
      <div class="flex items-center gap-8">
        <twaice-fe-svg-icon name="user-cog" source="tabler" color="#040F26" size="20px" />
        <span>Settings</span>
      </div>
    </li>

    <li nz-menu-item (click)="logout()" data-test="log-out" [attr.data-intercom-target]="intercomTarget + '-settings-sign-out'">
      <div class="flex items-center gap-8">
        <twaice-fe-svg-icon name="logout" source="tabler" color="#040F26" size="20px" />
        <span>Sign out</span>
      </div>
    </li>

    <li *ngIf="authService.isInternalUser()" class="px-8">
      <div class="flex items-center gap-8">
        <label class="px-4" nz-checkbox [ngModel]="useCustomerNaming" (ngModelChange)="switchCustomerNaming()"
          >Use Customer Naming</label
        >
      </div>
    </li>
  </ul>
</nz-dropdown-menu>
