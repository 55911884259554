import { Component } from '@angular/core';
import * as demoLinks from '@twaice-fe/shared/constants';

@Component({
  selector: 'twaice-fe-analytics-upsell',
  templateUrl: './analytics-upsell.component.html',
  styleUrl: './analytics-upsell.component.scss',
  standalone: false,
})
export class AnalyticsUpsellComponent {
  demoLinks = demoLinks;
}
