import { Component, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService, SystemsService } from '@twaice-fe/frontend/shared/services';
import {
  ANALYTICS_UPSELL_ROUTE,
  ENERGY_BASE_ROUTE,
  ENERGY_INCIDENTS_ROUTE,
  ENERGY_PERFORMANCE_MANAGER_ROUTE,
  ENERGY_PREDICTION_ROUTE,
  ENERGY_MONITORING_ROUTE,
  ENERGY_SAFETY_ROUTE,
  MOBILITY_BASE_ROUTE,
  REPORTING_ROUTE,
  ENERGY_WARRANTY_TRACKER_ROUTE,
} from '@twaice-fe/shared/constants';
import { CustomerType, Solution, User } from '@twaice-fe/shared/models';
import { actions, selectors } from '@twaice-fe/frontend/shared/store';
import { Observable } from 'rxjs';

const { configsAction } = actions;
const { configsSelectors } = selectors;

@Component({
  selector: 'twaice-fe-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  standalone: false,
})
export class ToolbarComponent {
  loggedInUser$: Observable<User>;
  username: string;

  title: string;
  showAnalyticsUpsell = false;
  intercomTarget = 'dashboard-layout-toolbar';

  isDemoEnabled = true;
  useCustomerNaming = true;

  constructor(
    private destroy$: DestroyRef,
    private systemsService: SystemsService,
    private router: Router,
    protected authService: AuthService,
    protected store: Store
  ) {
    this.loggedInUser$ = this.authService.getUserObservable();

    this.store
      .select(configsSelectors.getCustomerType)
      .pipe(takeUntilDestroyed())
      .subscribe((customerType) => {
        switch (customerType) {
          case CustomerType.ENERGY:
            this.title = 'Energy Analytics';
            return;
          case CustomerType.MOBILITY:
            this.title = 'E-mobility Analytics Suite';
            return;
          case CustomerType.MODELS:
            this.title = 'Analytics Suite';
            this.showAnalyticsUpsell = true;
            return;
        }
      });

    this.store
      .select(configsSelectors.getAvailableSolutionList)
      .pipe(takeUntilDestroyed())
      .subscribe((solutions) => {
        if (solutions.includes(Solution.MODEL_DEMO)) {
          this.isDemoEnabled = true;
        } else {
          this.isDemoEnabled = false;
        }
      });

    this.store
      .select(configsSelectors.getUseCustomerNaming)
      .pipe(takeUntilDestroyed())
      .subscribe((useCustomerNaming) => (this.useCustomerNaming = useCustomerNaming));
  }

  /**
   * Logs the user out, resets configuration, and navigates to the login page.
   */
  async logout() {
    this.systemsService.resetConfig();
    await this.authService.logout();
  }

  navigateToBaseUrl() {
    this.store
      .select(configsSelectors.getBaseUrl)
      .pipe(takeUntilDestroyed(this.destroy$))
      .subscribe((baseUrl) =>
        this.router.navigate([this.showAnalyticsUpsell ? '/analytics' : baseUrl], { queryParamsHandling: 'preserve' })
      );
  }

  openHelpCenter(): void {
    window.open('https://help.twaice.cloud', '_blank');
  }

  baseUrlActive() {
    return (
      this.router.url.includes(ENERGY_BASE_ROUTE) ||
      this.router.url.includes(ENERGY_PERFORMANCE_MANAGER_ROUTE) ||
      this.router.url.includes(ENERGY_PREDICTION_ROUTE) ||
      this.router.url.includes(ENERGY_WARRANTY_TRACKER_ROUTE) ||
      this.router.url.includes(ENERGY_INCIDENTS_ROUTE) ||
      this.router.url.includes(ENERGY_MONITORING_ROUTE) ||
      this.router.url.includes(ENERGY_SAFETY_ROUTE) ||
      this.router.url.includes(REPORTING_ROUTE) ||
      this.router.url.includes(ANALYTICS_UPSELL_ROUTE) ||
      this.router.url.includes(MOBILITY_BASE_ROUTE)
    );
  }

  switchCustomerNaming() {
    this.store.dispatch(configsAction.useCustomerNamingChanged({ useCustomerNaming: !this.useCustomerNaming }));
  }
}
