import { Injectable } from '@angular/core';
import { CurrentEnvironment } from '@twaice-fe/environments/shared';
import { LoginConfig } from '@twaice-fe/shared/models';
import { Amplify } from 'aws-amplify';
import { keysToCamel } from '@twaice-fe/shared/utilities';

@Injectable({
  providedIn: 'root',
})
export class AmplifyInternalService {
  constructor() {}

  async setupAmplifyConfig(baseUrlApi: string) {
    const loginConfig = this.getLoginConfig(baseUrlApi, window.location.origin);
    CurrentEnvironment.setLoginConfig(loginConfig);

    if (!loginConfig) return;
    Amplify.configure({
      Auth: {
        region: loginConfig.awsRegion,
        userPoolId: loginConfig.userPoolId,
        userPoolWebClientId: loginConfig.appClientId,
        oauth: loginConfig.ssoConfig
          ? {
              domain: loginConfig.ssoConfig.domain,
              scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
              redirectSignIn: loginConfig.ssoConfig.redirectUrl,
              redirectSignOut: loginConfig.ssoConfig.redirectUrl,
              responseType: 'code',
            }
          : {},
      },
    });
  }

  private getLoginConfig = (baseUrlApi: string, origin: string): LoginConfig => {
    let attempt = 0;
    const maxRetries = 5;

    while (attempt < maxRetries) {
      const xhr = new XMLHttpRequest();

      xhr.open('GET', baseUrlApi + `authn-config?domain_origin=${origin}`, false);
      xhr.send();

      if (xhr.status === 200) {
        return keysToCamel(JSON.parse(xhr.responseText)) as LoginConfig;
      }
      attempt++;
    }

    return undefined;
  };
}
