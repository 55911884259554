import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from '@twaice-fe/frontend/shared/services';
import { actions } from '@twaice-fe/frontend/shared/store';

const { authActions } = actions;
@Component({
  templateUrl: './missing-configuration.component.html',
  selector: 'twaice-fe-missing-configuration',
  styleUrls: ['./error-component-style.scss'],
  standalone: false,
})
export class MissingConfigurationComponent {
  constructor(
    private authService: AuthService,
    private router: Router,
    protected store: Store
  ) {}

  async signOut() {
    await this.authService.logout();
  }
}
