import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '@env/fe';
import { AuthService, SystemsService } from '@twaice-fe/frontend/shared/services';
import { combineLatest, combineLatestWith, distinctUntilChanged, from } from 'rxjs';

@Component({
  selector: 'twaice-fe-professional-services',
  templateUrl: './professional-services.component.html',
  standalone: false,
})
export class ProfessionalServicesComponent implements OnInit {
  pshUrl: SafeResourceUrl;

  constructor(
    private authService: AuthService,
    private systemService: SystemsService,
    private domSanitizer: DomSanitizer,
    private destroy$: DestroyRef
  ) {}

  ngOnInit() {
    combineLatest([this.authService.isInternalUser(), this.systemService.getSystemUiConfiguration()])
      .pipe(distinctUntilChanged(), combineLatestWith(from(this.authService.getTokens())), takeUntilDestroyed(this.destroy$))
      .subscribe(([[isInternal, uiConfig], tokens]) => {
        let customerBk: string;
        if (!uiConfig) {
          customerBk = this.authService.getUsersCustomerBK();
        } else {
          customerBk = uiConfig.customerBk;
        }

        let pshUrl = 'https://psh.twaice.cloud/psh/login';
        if (environment.name === 'dev') {
          pshUrl = 'https://psh.dev.twaice.cloud/psh/login';
        } else if (environment.name === 'stg') {
          pshUrl = 'https://psh.stg.twaice.cloud/psh/login';
        }

        pshUrl = pshUrl + `?jwt_token=${tokens.idToken}&refresh_token=${tokens.refreshToken}`;
        if (isInternal && customerBk) {
          pshUrl = pshUrl + `&desired_customer_bk=${customerBk}`;
        }
        pshUrl = pshUrl + `&embed=true`;
        this.pshUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(pshUrl);
      });
  }
}
