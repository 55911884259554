import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfessionalServicesComponent } from './professional-services.component';
import { SafePipeModule } from 'safe-pipe';

@NgModule({
  declarations: [ProfessionalServicesComponent],
  imports: [CommonModule, SafePipeModule],
})
export class ProfessionalServicesModule {}
